import { createRouter, createWebHistory } from "vue-router"
import Home from "./components/Home.vue"
import History from "./components/History.vue"

const routes = [
  { path: "/",          redirect: "/products" },
  { path: "/products",  component: Home       },
  { path: "/histories", component: History    },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
