/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api001",
            "endpoint": "https://pg138ki9hk.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "product-master",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "history-master",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
