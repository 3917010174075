<template>
  <v-app>
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "./components/layouts/Header.vue"
import Footer from "./components/layouts/Footer.vue"

export default {
  name: "App",
  components: {
    Header,
    Footer
  }
}
</script>
