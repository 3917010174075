<template>
  <v-container class="py-1">
    <div class="text-link">
      <router-link :to="'/products' + makeQueryParam()">
        <v-btn
          :prepend-icon="mdiArrowLeft"
          rounded="pill"
          color="cyan"
          class="text-white my-2"
        >
          戻る
        </v-btn>
      </router-link>
    </div>
    <v-alert
      type="success"
      text="過去7日間に販売がされていた商品を履歴として残しています"
      class="ma-2"
    >
    </v-alert>
    <v-table
      density="compact"
      hover="true"
    >
      <thead>
        <tr class="v-table--thead">
          <th>日付</th>
          <th>商品名</th>
          <th class="d-none-sm">販売店</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="salesOn in groupKeys"
          :key="salesOn"
        >
          <tr
            v-for="(item, index) in groupedItems[salesOn]"
            :key="index"
          >
            <td
              v-if="index == 0"
              :rowspan="groupedItems[salesOn].length"
              class="v-table--group-title v-table--td-nowrap pt-2"
            >
              {{ dateYYMM(salesOn) }}
            </td>
            <td>{{ item.Title }}</td>
            <td class="d-none-sm v-table--td-nowrap">{{ dealerName(item.Dealer) }}</td>
          </tr>
        </template>
      </tbody>
    </v-table>
    <div class="py-6 text-center">
      {{ message }}
      <InfiniteLoading @infinite="loadData">
        <template #complete>&nbsp;</template>
      </InfiniteLoading>
    </div>
  </v-container>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js"
import { API } from "aws-amplify"
import InfiniteLoading from "v3-infinite-loading"
import "v3-infinite-loading/lib/style.css"
import { fromUnixTime } from "date-fns"
import format from "date-fns/format"

export default {
  name: "History",
  components: {
    InfiniteLoading
  },
  data() {
    return {
      mdiArrowLeft,
      message: "",
      groupedItems: {},
      groupKeys: [],
      LastKey: {
        pk:    "",
        sk:    "",
        value: ""
      }
    }
  },
  methods: {
    makeQueryParam() {
      const url = new URL(window.location.href)
      let params = []

      const queryParams = url.searchParams
      for (const [key, value] of queryParams) {
        params.push(key + "=" + value)
      }
      if (params.length > 0) {
        return "?" + params.join("&")
      }

      return ""
    },
    loadData: async function ($state) {
      let params = {
        headers:               {},
        queryStringParameters: {}
      }

      if (this.LastKey.pk != "" && this.LastKey.sk != "" && this.LastKey.value != "") {
        params.queryStringParameters = this.LastKey
      }

      let url = new URL(window.location.href)
      let grade = url.searchParams.get("grade")
      if (grade != null) {
        params.queryStringParameters.grade = grade
      }
      let dealer = url.searchParams.get("dealer")
      if (dealer != null) {
        params.queryStringParameters.dealer = dealer
      }

      const result = await API.get("api001", "/histories", params)

      let lk = result.LastEvaluatedKey
      this.groupyItem(result.Items)
      if (lk) {
        this.LastKey.pk    = (lk.DataType || lk.Grade || lk.Dealer || lk.FilterData)
        this.LastKey.sk    = lk.SalesOn
        this.LastKey.value = lk.Title
        $state.loaded()
      } else {
        this.LastKey.pk    = ""
        this.LastKey.sk    = ""
        this.LastKey.value = ""
        $state.complete()

        if (result.Items.length == 0) this.message = "販売履歴が見つかりませんでした"
      }
    },
    dateYYMM(date) {
      return format(fromUnixTime(parseInt(date)), "MM/dd")
    },
    dealerName(value) {
      if (value == "amazon.co.jp") {
        return "Amazon"
      } else if (value == "p-bandai.jp") {
        return "プレバン"
      } else if (value == "yodobashi.com") {
        return "ヨドバシ.com"
      } else if (value == "dmm.com") {
        return "DMM通販"
      } else {
        return "（不明）"
      }
    },
    groupyItem(items) {
      for (let item of items) {
        if (item.SalesOn in this.groupedItems) {
          this.groupedItems[item.SalesOn].push(item)
        } else {
          this.groupedItems[item.SalesOn] = [item]
          this.groupKeys.push(item.SalesOn)
        }
      }
    }
  }
}
</script>

<style>
.v-table--thead {
  font-weight: 600 !important;
}

.v-table--group-title {
  vertical-align: top;
  font-weight: 600;
}

.v-table--td-nowrap {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .d-none-sm {
    display: none !important;
  }
}
</style>
