<template>
  <v-card
    class="mx-auto"
    :href="item.URL"
    target="_blank"
  >
    <v-img
      :src="'https://check-product.s3.ap-northeast-1.amazonaws.com/images/' + encodeFilename(item.Image)"
      height="15rem"
      contain
      class="ma-2"
    ></v-img>

    <div class="title-text text-left px-4">
      {{ item.Title }}
    </div>

    <v-card-text class="text-right pa-2 px-4">
      <div class="text-h6 text-red">
        {{ formatPrice(item.Price) }}
      </div>
      <div class="text-caption text-grey-lighten-1">
        {{ item.Dealer }}
        {{ fromNow(item.SalesAt) }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { fromUnixTime } from "date-fns"
import formatDistanceToNow from "date-fns/formatDistanceToNow"

export default {
  name: "card",
  components: {
  },
  props: {
    item: Object
  },
  data() {
    return {
      items: []
    }
  },
  methods: {
    formatPrice(price) {
      return parseInt(price).toLocaleString("ja-JP", {style: "currency", currency: "JPY"})
    },
    fromNow(date) {
      return formatDistanceToNow(fromUnixTime(parseInt(date)), {addSuffix: true})
    },
    encodeFilename(filename) {
      return encodeURIComponent(filename)
    }
  }
}
</script>

<style scoped>
.title-text {
  font-size: 1rem;
  text-decoration: none;
  height: 2.6rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.3rem;
}
</style>
