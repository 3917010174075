<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <template v-slot:activator="{ props }">
      <a v-bind="props" class="attention">
        注意事項
      </a>
    </template>

    <v-card>
      <v-card-text>
        <div class="ma-4">
          <ul>
            <li>このサイトを経由して購入した商品についての一切の責任は負えません</li>
            <li>なるべく迅速な更新を心がけていますが、売り切れてしまっている場合もありますのでご了承ください</li>
            <li>表示している価格が変わっている場合もありますので、購入の際は注意してください</li>
            <li>一部のリンクはアフィリエイトを使用しています</li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="dialog = false">閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "attention",
  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style scoped>
.attention {
  color: cyan;
  cursor: pointer;
}

.attention:hover {
  text-decoration: underline;
}
</style>
