<template>
  <v-container class="py-1">
    <div class="text-right text-link">
      <router-link :to="'/histories' + makeQueryParam()">
        <v-btn
          :prepend-icon="mdiHistory"
          rounded="pill"
          color="cyan"
          class="text-white mt-2"
        >
          過去の販売履歴
        </v-btn>
      </router-link>
    </div>
  </v-container>
  <List/>
</template>

<script>
import { mdiHistory } from "@mdi/js"
import List from "./List.vue"

export default {
  name: "Home",
  components: {
    List
  },
  data() {
    return {
      mdiHistory
    }
  },
  methods: {
    makeQueryParam() {
      const url = new URL(window.location.href)
      let params = []

      const queryParams = url.searchParams
      for (const [key, value] of queryParams) {
        params.push(key + "=" + value)
      }
      if (params.length > 0) {
        return "?" + params.join("&")
      }

      return ""
    }
  }
}
</script>
