<template>
  <header>
    <v-container>
      <v-row class="align-center">
        <v-col
          cols="6"
          sm="3"
          md="2"
          lg="2"
        >
          <a href="/">
            <v-img
              width="10rem"
              src="/logo.svg"
            ></v-img>
          </a>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          md="10"
          lg="10"
          class="text-white px-2"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              このサイトは各ECサイトのガンプラの発売情報を掲載しています<br/>
              ご利用の際は<Attention/>を確認してからお使いください
            </v-col>
            <v-col cols="12" class="py-0">
              <v-toolbar
                density="compact"
                flat
                color="#2c3e50"
                class="text-white"
              >
                <div class="mr-2">
                  <v-icon :icon="mdiFilter"></v-icon>
                  <span class="hidden-sm-and-down pr-4">
                    フィルター
                  </span>
                </div>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      rounded="pill"
                      variant="outlined"
                      color="cyan"
                      class="mr-2"
                    >
                      グレード: {{  filterGrade() }}
                    </v-btn>
                  </template>
                  <v-list
                    :items="grades"
                    density="compact"
                    active-color="cyan"
                    v-model="selectedGrade"
                    @click:select="selectGrade"
                  >
                    <v-list-item
                      v-for="item in grades"
                      :key="item.value"
                      :title="item.title"
                      :value="item.value"
                      :active="item.title == filterGrade()"
                    ></v-list-item>
                  </v-list>
                </v-menu>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      rounded="pill"
                      variant="outlined"
                      color="cyan"
                    >
                      販売店： {{ filterDealer() }}
                    </v-btn>
                  </template>
                  <v-list
                    density="compact"
                    active-color="cyan"
                    @click:select="selectDealer"
                  >
                    <v-list-item
                      v-for="item in dealers"
                      :key="item.value"
                      :title="item.title"
                      :value="item.value"
                      :active="item.title == filterDealer()"
                    ></v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>

<script>
import { mdiFilter } from "@mdi/js"
import Attention from "./Attention.vue"

export default {
  name: "Header",
  components: {
    Attention
  },
  data() {
    return {
      mdiFilter,
      grades: [
        { title: "全て",   value: ""   },
        { title: "HG",     value: "HG" },
        { title: "MG",     value: "MG" },
        { title: "RG",     value: "RG" },
        { title: "SD",     value: "SD" },
        { title: "PG",     value: "PG" },
        { title: "EG",     value: "EG" },
        { title: "RE",     value: "RE" },
        { title: "その他", value: "-"  }
      ],
      dealers: [
        { title: "全て",         value: ""              },
        { title: "Amazon",       value: "amazon.co.jp"  },
        { title: "プレバン",     value: "p-bandai.jp"   },
        { title: "ヨドバシ.com", value: "yodobashi.com" },
        { title: "DMM通販",      value: "dmm.com"       }
      ]
    }
  },
  methods: {
    filterGrade() {
      let url = new URL(window.location.href)
      if (url.searchParams.get("grade")) {
        for (let grade of this.$data.grades) {
          if (grade.value == url.searchParams.get("grade")) {
            return grade.title
          }
        }
      }
      return "全て"
    },
    filterDealer() {
      let url = new URL(window.location.href)
      if (url.searchParams.get("dealer")) {
        for (let dealer of this.$data.dealers) {
          if (dealer.value == url.searchParams.get("dealer")) {
            return dealer.title
          }
        }
      }
      return "全て"
    },
    selectGrade(item) {
      let url = new URL(window.location.href)
      if (item.id != "") {
        if (!url.searchParams.get("grade")) {
          url.searchParams.append("grade", item.id)
        } else {
          url.searchParams.set("grade", item.id)
        }
      } else {
        url.searchParams.delete("grade")
      }
      location.href = url
    },
    selectDealer(item) {
      let url = new URL(window.location.href)
      if (item.id != "") {
        if (!url.searchParams.get("dealer")) {
          url.searchParams.append("dealer", item.id)
        } else {
          url.searchParams.set("dealer", item.id)
        }
      } else {
        url.searchParams.delete("dealer")
      }
      location.href = url
    }
  }
}
</script>

<style>
header {
  background-color: #2c3e50;
}

.text-link a {
  color: rgb(0, 140, 140);
  text-decoration: none;
}

.text-link a:hover {
  text-decoration: underline;
}
</style>
