<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(item, index) in items"
        :key="index"
      >
        <Card :item="item"/>
      </v-col>
    </v-row>
    <div class="py-6 text-center">
      {{ message }}
      <InfiniteLoading @infinite="loadData">
        <template #complete>&nbsp;</template>
      </InfiniteLoading>
    </div>
  </v-container>
</template>

<script>
import InfiniteLoading from "v3-infinite-loading"
import "v3-infinite-loading/lib/style.css"
import Card from "./Card.vue"
import { API } from "aws-amplify"

export default {
  name: "List",
  components: {
    InfiniteLoading,
    Card
  },
  data() {
    return {
      message: "",
      items:   [],
      LastKey: {
        pk:    "",
        sk:    "",
        value: ""
      }
    }
  },
  methods: {
    loadData: async function ($state) {
      let params = {
        headers:               {},
        queryStringParameters: {}
      }

      if (this.LastKey.pk != "" && this.LastKey.sk != "" && this.LastKey.value != "") {
        params.queryStringParameters = this.LastKey
      }

      let url = new URL(window.location.href)
      let grade = url.searchParams.get("grade")
      if (grade != null) {
        params.queryStringParameters.grade = grade
      }
      let dealer = url.searchParams.get("dealer")
      if (dealer != null) {
        params.queryStringParameters.dealer = dealer
      }

      const result = await API.get("api001", "/new-products", params)

      let lk = result.LastEvaluatedKey
      this.items.push(...result.Items)
      if (lk) {
        this.LastKey.pk    = (lk.DataType || lk.Grade || lk.Dealer || lk.FilterData)
        this.LastKey.sk    = lk.SalesAt
        this.LastKey.value = lk.URL
        $state.loaded()
      } else {
        this.LastKey.pk    = ""
        this.LastKey.sk    = ""
        this.LastKey.value = ""
        $state.complete()

        if (this.items.length == 0) this.message = "商品が見つかりませんでした"
      }
    }
  }
}
</script>
