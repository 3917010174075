import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./vuetify"

import { Amplify } from "aws-amplify"
import awsconfig from "./aws-exports"

Amplify.configure(awsconfig);

const app = createApp(App)
app.use(router).use(vuetify)
app.mount("#app")
