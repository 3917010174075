<template>
  <v-fab-transition>
    <v-btn
      color="cyan"
      fab
      large
      elevation="8"
      position="fixed"
      class="text-white v-btn--bottom-right"
      :icon="mdiChevronUp"
      @click="scrollToTop"
      v-show="active"
    >
    </v-btn>
  </v-fab-transition>
  <v-footer
    absolute
    color="#2c3e50"
    class="text-white v-footer--height"
  >
    <div class="ml-auto">
      &copy; 2023 Gun+
    </div>
  </v-footer>
</template>

<script>
import { mdiChevronUp } from "@mdi/js"

export default {
  name: "Footer",
  data() {
    return {
      mdiChevronUp,
      active: false,
      scroll: 0
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow)
  },
  methods: {
    scrollWindow() {
      const top = 200
      this.scroll = window.scrollY
      if (top <= this.scroll) {
        this.active = true
      } else {
        this.active = false
      }
    },
    scrollToTop() {
      window.scrollTo({
        top:      0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style>
.v-footer--height {
  max-height: 2.25rem;
}

.v-btn--bottom-right {
  bottom: 3rem;
  right:  2rem;
}
</style>
